import Image from "../image/image";
import tiago from "../../assets/tr.png";
import Separator from "../separator/separator";
import { aboutData } from "../../assets/data";

const About = () => {
  function createMarkup(data) {
    return { __html: data };
  }

  return (
    <div id="about" className="slide slide--md animate__animated">
      <Separator title="about" direction="left" />
      <div className="about">
        <div className="about__content">
          <p dangerouslySetInnerHTML={createMarkup(aboutData.about1)}></p>
          <p dangerouslySetInnerHTML={createMarkup(aboutData.about2)}></p>
          <p dangerouslySetInnerHTML={createMarkup(aboutData.about3)}></p>
          <p>{aboutData.about4}</p>
          <ul>
            <li>
              <span>{aboutData.technologies[0]}</span>
            </li>
            <li>
              <span>{aboutData.technologies[1]}</span>
            </li>
            <li>
              <span>{aboutData.technologies[2]}</span>
            </li>
            <li>
              <span>{aboutData.technologies[3]}</span>
            </li>
            <li>
              <span>{aboutData.technologies[4]}</span>
            </li>
            <li>
              <span>{aboutData.technologies[5]}</span>
            </li>
          </ul>
        </div>
        <div className="about__image">
          <Image source={tiago} alt='tiago rodrigues' additionalClasses="accent--line" />
        </div>
      </div>
    </div>
  );
};

export default About;
