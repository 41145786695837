import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "../image/image";

const GalleryItem = (props) => {
  const direction = props.direction;
  const projectData = props.projectData;
  const imageSource = props.imageSource;
  const imageAlt = props.imageAlt;

  return (
    <div className={`gallery-item ${direction}`}>
      <div className={`gallery-item__image ${direction}`}>
        <a
          href="https://d21me35yvyx82u.cloudfront.net/"
          target="_blank"
          rel="noreferrer"
        >
          <Image
            source={imageSource}
            alt={imageAlt}
            additionalClasses={"cursor--pointer"}
          />
        </a>
      </div>
      <div className={`gallery-item__description ${direction}`}>
        <h3>{projectData.name}</h3>
        <div className="card gallery-item__card">
          <span>{projectData.description}</span>
        </div>
        <div className={`gallery-item__tech ${direction}`}>
          {projectData.technologies.map((tech, i) => (
            <small key={i}>{tech}</small>
          ))}
        </div>
        <div className="gallery-item__links">
          <a
            href="https://d3vaxryi9c0u9l.cloudfront.net/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faArrowUpRightFromSquare}></FontAwesomeIcon>
          </a>
        </div>
      </div>
    </div>
  );
};

export default GalleryItem;
