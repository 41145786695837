import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import cv from '../../assets/cv_tiago_rodrigues.pdf'

const Footer = () => {
  return (
    <div className="footer">
      <small className="footer__text">
        built by Tiago Rodrigues using React
      </small>
      <div className="footer__icons">
        <a href="https://www.linkedin.com/in/tmcrs/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon>
        </a>
        <a href="https://github.com/tmcrs123" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faGithub}></FontAwesomeIcon>
        </a>
        <a href={cv} download="cv-tiago_rodrigues" rel="noreferrer">
          <FontAwesomeIcon icon={faFile}></FontAwesomeIcon>
        </a>
      </div>
    </div>
  );
};

export default Footer;
