import Carousel from "../carousel/carousel";
import Separator from "../separator/separator";

const Work = () => {
  return (
    <div id="work" className="slide slide--sm animate__animated">
      <Separator title="work" direction="center" />
      <div className="work__container">
        <Carousel id="carousel" />
      </div>
    </div>
  );
};

export default Work;
