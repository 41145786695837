const Separator = (props) => {
  
  const direction = props.direction;
  const title = props.title;

  if(direction === 'center'){
    return twoSidedSeparator(title)
  } else {
    return normalSeparator(direction, title)
  }

  
};

function normalSeparator(direction, title){
  return (
    <div className={`separator separator--${direction}`}>
      <h2>{title}</h2>
      <span className={`separator__line separator--${direction}`}></span>
    </div>
  )
}

function twoSidedSeparator(title){
  return (
    <div className="separator separator--centered">
      <span className={`separator__line separator--right separator--half`}></span>
      <h2 className="separator__title--center">{title}</h2>
      <span className={`separator__line separator--left separator--half`}></span>
    </div>
  )
}

export default Separator;
