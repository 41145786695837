import Landing from "../landing/landing";
import Navbar from "../navbar/navbar";
import About from "../about/about";
import Work from "../work/work";
import Gallery from "../gallery/gallery";
import ContactMe from "../contact-me/contact-me";
import { useEffect } from "react";
import SideContent from "../side-content/side-content";
import Footer from "../footer/footer";

const Layout = () => {
  useEffect(() => {
    const slidesObserver = new IntersectionObserver(
      (slides) => {
        slides.forEach((slide) => {
          slide.target.classList.toggle(
            "animate__fadeIn",
            slide.isIntersecting
          );

          slide.target.classList.add("animate__slow");

          if (slide.isIntersecting) slidesObserver.unobserve(slide.target);
        });
      },
      {
        threshold: 0,
      }
    );

    const aboutSlideObserver = new IntersectionObserver((slides) => {
      slides.forEach(
        (aboutSlide) => {
          if (aboutSlide.isIntersecting && window.innerWidth > 960) {
            sideContent.style.display = "flex";
            sideContent.classList.add("animate__fadeIn");
          }
        },
        {
          threshold: 1,
        }
      );
    });

    const slides = document.querySelectorAll(".slide");
    const aboutSlide = document.getElementById("about");
    const sideContent = document.getElementsByClassName("side-content")[0];
    slides.forEach((slide) => slidesObserver.observe(slide));
    aboutSlideObserver.observe(aboutSlide);
  }, []);

  return (
    <div>
      <Navbar />
      <SideContent />
      <div className="app__content">
        <Landing />
        <div className="flow">
          <About />
          <Work />
          <Gallery />
          <ContactMe />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
