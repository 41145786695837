import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useRef } from "react";
import logo from "../../assets/logo.svg";

const Navbar = () => {
  const [isNavbarOpen, _setIsNavbarOpen] = useState(false);
  const [navbarButton, setNavbarButton] = useState(faBars);

  let isNavbarOpenRef = useRef(isNavbarOpen);

  const setIsNavbarOpen = (isOpen) => {
    isNavbarOpenRef.current = isOpen;
    _setIsNavbarOpen(isOpen);
  };

  useEffect(() => {
    if (isNavbarOpen) {
      setNavbarButton(faTimes);
    } else {
      setNavbarButton(faBars);
    }
  }, [isNavbarOpen]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
  }, []);

  function handleResize() {
    if (isNavbarOpenRef.current) handleClick();
  }

  let prevY = 0;
  function handleScroll() {
    let currentY = window.scrollY;

    if (prevY === currentY) return; //same position
    if (currentY === 0) return; //top
    if (isNavbarOpenRef.current) return; //nav is open, dont scroll

    if (prevY < currentY) {
      document.getElementById("nav").style.top = "-100px";
    } else {
      document.getElementById("nav").style.top = "0";
    }

    prevY = currentY;
  }

  function handleClick() {
    setIsNavbarOpen(!isNavbarOpen);
  }

  function handleNavItemClick() {
    if(isNavbarOpen){
      setIsNavbarOpen(false)
    }
  }

  function isVisible() {
    return isNavbarOpen ? "nav--visible" : "";
  }

  return (
    <nav id="nav">
      <li className="nav__logo animate__animated animate__fadeInDown">
        <img className="nav__logo__img" alt="the logo for this site" src={logo} />
      </li>
      <ul className={`nav__menu ${isNavbarOpen ? "nav__menu--open" : ""}`}>
        <li className="nav__menu__toggle">
          <button className="btn--icon" onClick={handleClick}>
            <FontAwesomeIcon icon={navbarButton}/>
          </button>
        </li>

        <li className={`${isVisible()} animate__animated animate__fadeInDown animate__delay-1`} onClick={handleNavItemClick}>
          <a href="#about">about</a>
        </li>
        <li className={`${isVisible()} animate__animated animate__fadeInDown animate__delay-2`} onClick={handleNavItemClick}>
          <a href="#work">work</a>
        </li>
        <li className={`${isVisible()} animate__animated animate__fadeInDown animate__delay-3`} onClick={handleNavItemClick}>
          <a href="#gallery">gallery</a>
        </li>
        <li className={`${isVisible()} animate__animated animate__fadeInDown animate__delay-4`} onClick={handleNavItemClick}>
          <a href="#contact">contact</a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
